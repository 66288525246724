@keyframes wave-animation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
  75% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}

.wave-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px; /* Ajusta la altura según tus necesidades */
}

.wave {
  width: 5px; /* Ajusta el ancho de cada div según tus necesidades */
  height: 50%; /* Toma la altura completa del contenedor */
  margin: 0 2px; /* Espacio entre los divs */
  background-color: #395B64; /* Color de los divs */
  animation: wave-animation 0.75s ease-in-out infinite;
  border-radius: 10px; /* Duración, timing function y repetición de la animación */
}
